<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title class="">
            {{ user.name }}
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="2">
              <v-dialog
                :retain-focus="false"
                v-model="dialog"
                persistent
                max-width="800px"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    Update Image
                  </v-btn>
                </template>
                <v-card>
                  <v-form>
                    <v-card-title>
                      <span class="headline">Add Invitation Card</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <ImageComp
                              :singleImage="mainImagePreview"
                              @stepper="winStepper"
                              :componentType="'trendingImage'"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialogclose()">
                        Close
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="uploadImage()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col>
          </v-card-title>
          <div v-if="user">
            <v-layout wrap justify-start>
              <v-flex
                xs12
                sm6
                md3
                pa-2
                v-for="(item, i) in user.imageCollection"
                :key="i"
              >
                <v-img contain :src="baseURL + item">
                  <v-layout wrap justify-end>
                    <v-flex xs2 text-right>
                      <v-badge
                        offset-x="20"
                        offset-y="2"
                        small
                        overlap
                        avatar
                        color="#FF1313"
                      >
                        <template v-slot:badge>
                          <v-dialog
                            v-model="item.dialogDelete"
                            max-width="600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-avatar>
                                <v-icon
                                  v-on="on"
                                  v-bind="attrs"
                                  >mdi-close</v-icon
                                >
                              </v-avatar>
                            </template>
                            <v-card>
                              <v-card-title
                                style="
                                  font-family: poppinsregular;
                                  font-size: 15px;
                                  color: #000;
                                "
                                >Are you sure you want to delete this
                                Image?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  small
                                  @click="item.dialogDelete = false"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  small
                                  @click="remove(i)"
                                  >OK</v-btn
                                >
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </template>
                        <template v-slot:placeholder>
                          <ImageLoader />
                        </template>
                      </v-badge>
                    </v-flex>
                  </v-layout>
                </v-img>
              </v-flex>
            </v-layout>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      msg: null,
      ServerError: false,
      showsnackbar: false,
      mainImagePreview: "",
      user: [],
      frz: [],
      appLoading: false,
      dialog: false,
      userStatus: null,
      userRole: localStorage.getItem("role"),
      product: [],
      photos: [],
      trendingImage: null,
      image: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "trendingImage") {
        this.trendingImage = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/invitation/category/get",
        params: {
          id: this.$route.query.userId,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.user = response.data.data;
            this.frz = response.data.friends;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.dialog = false;
    },
    uploadImage() {
      let formData = new FormData();
      formData.append("id", this.$route.query.userId);
      formData.append("images", this.trendingImage);

      axios({
        method: "POST",
        url: "/invitation/category/UploadImagesingle",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    remove(r) {
      var data = {};
      data["position"] = r + 1;
      data["id"] = this.$route.query.userId;
      axios({
        url: "/invitation/category/RemoveImagesingle",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.dialogDelete = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 18px;
}
.item_value {
  font-size: 15px;
}
.Content {
  text-align: center;
  position: relative;
  padding-top: 210px;
}
.ContentFestival {
  text-align: center;
  position: relative;
  padding-top: 210px;
}
</style>
